// Here you can add other styles

.btn {
	cursor: pointer;
}

.table th, .table td {
	vertical-align: middle;
}

.card-header-title {
	font-size: 1.3rem;
	line-height: 35px;
	margin: 0;
}

.photo {
	background-color: #f2f2f2;
	background-position: center center;
	background-size: cover;
	border: 1px solid #e2e2e2;
	border-radius: 9999px;
	display: block;
	height: 60px;
	width: 60px;
}

.rdsu-image {
	text-align: center;
}

.rdsu-image img {
	max-height: 100%;
	max-width: 100%;
}

.react-bs-container-body {
	min-height: 500px;
}